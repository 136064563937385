<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-btn
          class="elevation-0 rounded-r-0"
          large
          color="#5CB7B1"
          @click="openWareDetailsModal"
        >
          <v-icon>mdi-open-in-app</v-icon>Открыть товары списком
        </v-btn>
        <!--<v-btn
          min-width="50px"
          width="50px"
          large
          dark
          :disabled="inProcess"
          class="elevation-0 rounded-l-0"
          @click="addGoodsItem"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>-->
      </v-col>
    </v-row>

    <component
      :is="activeComponent"
      :ware-id="activeComponentProps.id"
      :ware-sort-index="activeComponentProps.sortIndex"
      :show.sync="showComponent"
    />
  </v-container>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      inProcess:false,
      showComponent: false,
      activeComponent: null,
      activeComponentProps: {
        id: null,
      }
    }
  },
  computed:{
    ...mapGetters({
      selectedWares:"dts/getSelectedWares"
    })
  },
  methods:{
    addGoodsItem() {
      this.inProcess = true
      this.$store.dispatch("dts/addNewGoodsItem")
        .then(() => this.$info("Товар добавлен"))
        .catch(() => this.$error('Не удалось создать товар'))
        .finally(() => this.inProcess = false)
    },
    openWareDetailsModal(){
      if(!this.selectedWares.length) this.$warning("Товары отсутствуют")
      const [ware] = this.selectedWares
      const {id, sort_index:sortIndex} = ware
      this.activeComponent = () => import('@/components/dts/goods/ware-details/ware-details-modal.vue')
      this.activeComponentProps = {
        ...this.activeComponentProps,
        id, sortIndex
      }
      this.showComponent = true
    },
  }
}
</script>
