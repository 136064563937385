<template>
  <div
    :id="block_id"
    v-click-outside="triggerOnFocus"
    class="document__row pt-1"
    :draggable="draggable"
    @dragstart="startDrag($event, document)"
    @mouseup="mouseUp"
  >
    <div class="document__select ">
      <v-checkbox
        tabindex="-1"
        dense
        class="pa-0 ml-1"
        hide-details
        color="#5CB7B1"
        :value="selectedDocs.includes(item.id)"
        @change="updateSelected"
      />
    </div>

    <div class="document__sort">
      <div
        class="allow-drag"
        @mousedown="mouseDown($event)"
      >
        {{ document.sort_index }}
      </div>
    </div>

    <div class="document__code">
      <v-text-field
        v-show="customFieldChecker(doc_types, document.doc_code, 'code')"
        ref="doc_code"
        v-model="document.doc_code"
        v-mask="'XXXXX'"
        outlined
        :label="index === 0 ? 'Код' : ''"
        dense
        hide-details="auto"
        background-color="white"
      />
      <v-tooltip
        bottom
        max-width="300px"
        :disabled="!document.doc_code || hideTooltips"
      >
        <template #activator="{ on }">
          <v-autocomplete
            v-show="!customFieldChecker(doc_types, document.doc_code, 'code')"
            ref="doc_code__custom"
            v-model="document.doc_code"
            auto-select-first
            :label="index === 0 ? 'Код' : ''"
            :items="doc_types"
            item-text="code"
            :filter="filterBySearchField"
            item-value="code"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            v-on="on"
          />
        </template>
        <span>{{ docName }}</span>
      </v-tooltip>

      <v-btn
        tabindex="-1"
        min-width="36px"
        max-width="36px"
        color="#5CB7B1"
        class="ml-1 elevation-0"
        @click="showDocuments"
      >
        <v-icon>
          mdi-format-list-bulleted
        </v-icon>
      </v-btn>
    </div> 
    <div class="document__date">
      <custom-date-picker
        ref="doc_date"
        v-model="document.doc_date"
        :label="index === 0 ? 'Дата документа' : ''"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
      />
    </div>
    <div class="document__documents-group">
      <v-autocomplete
        v-model="document.documents_group"
        :items="dts.declaration_type === 'DTS1' ? documentGroups : documentGroups2"
        hide-details
        outlined
        dense
        auto-select-first
        background-color="white"
        :label=" index === 0 ? 'Группа' : ''"
      />
    </div>
    <div
      v-show="document.documents_group == 8"
    >
      <div class="d-flex">
        <v-text-field      
          v-model="document.ware_range"
          outlined
          :label="'Товары'"
          dense
          hide-details="auto"
          background-color="white"
        />
        <v-text-field
          v-model="document.previous_reg_number"
          :label="'Регистрационный номер ДТ'"
          class="ml-2"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {deleteNulls} from "@/helpers/inputs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import {convertEmptyStringsToNull} from "@/helpers/objects";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import {showCatalog} from "@/events/statistics";
import {documentTypeSelected} from "@/events/statistics";
import CustomDatePicker from "@/components/shared/custom-date-picker"
import {highlightField} from '@/helpers/control'
import {DEAL_REGISTRATION_NOT_REQUIRED} from '@/constants'
import highlightFieldsChild from "@/mixins/control/highlight-fields-child";

export default {
  components: {
    CustomDatePicker
  },
  mixins: [blockAutoUpdate, filterBySearchFieldMixin, highlightFieldsChild],
  props: {
    index: {
      required: true,
      type: Number,
    },
    item: {
      required: true,
      type: Object,
    },
    draggable: {
      required: true,
      type: Boolean,
    },
    forSingleItem: {
      required: true,
      type: Boolean,
    },
    hideTooltips: {
      required: true,
      type: Boolean
    },
    selectedDocs: {
      required: true,
      type: Array
    },
    presentedDocuments: {
      required: true,
      type: Array
    },
    control: {
      required: true,
      type: Object
    },
    presentTypes: {
      required: true,
      type: Array
    },
  },
  data: () => ({
    selected: null,
    mousePressed: false,
    isIntersecting: false,
    parent: null,
    block_id: null,
    menu: false,
    document: {
      id: null,
      doc_code: null,
      doc_number: null,
      doc_date: null,
      documents_group: null,
      declaration_id: null,
      sort_index: null,
      ware_range: null,
      previous_reg_number: null
    },
    noRegistration: true,
    key: '',
    noRegistrationText: DEAL_REGISTRATION_NOT_REQUIRED,
    documentGroups:[
      {
        value:4,
        text:"4 - Номер и дата счета (счетов)"
      },
      {
        value:5,
        text:"5 - Номер и дата контракта (договора, соглашения)"
      },
      {
        value:6,
        text:"6 - Номера и даты документов, имеющих отношение к сведениям, указанным в графах 7-9"
      },
    ],
    documentGroups2:[
      {
        value:4,
        text:"4 - Номер и дата документа, являющегося основанием для поставки товаров"
      },
      {
        value:5,
        text:"5 - Номер и дата документа с принятыми решениями (таможенными органами/судебными органами) по ранее ввезенным товарам по документу, указанному в графе 4"
      },
      {
        value:8,
        text:"8 - Наименования, номера и даты документов, на основании которых заполнена декларация таможенной стоимости"
      },
    ]
  }),
  computed: {
    ...mapGetters({
      dts: "dts/getSelected",
      doc_types: "catalogs/getNsiTypesOfDocument",
      countries: "catalogs/getNsiCountries",
    }),
    docName() {
      const {name} =
      this.doc_types.find((i) => i.code === this.document.doc_code) || {};
      return name || "";
    },
  },
  watch: {
    control: {
      handler({path, index}) {
        if (path !== "" && this.index === index - 1) {
          this.$nextTick(() => {
            highlightField.call(this, {path})
          })
        }
      },
      deep: true,
      immediate: true
    },
    item: {
      handler(newVal) {
        this.block_id = `dts-present-document-${this.item.id}`;
        for (let key in newVal) {
          if (key in this.document) {
            this.document[key] = newVal[key];
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    documentTypeSelected.subscribe(this.checkAndUpdateDocType);
    const collection = [...document.getElementsByClassName("drop-zone")];
    const [parent] = collection;
    this.parent = parent;
  },
  beforeDestroy() {
    documentTypeSelected.unsubscribe();
  },
  methods: {
    onPresentTypeCodeChange(value) {
      if (value !== "2") {
        this.document.previous_reg_number = null
      }
      if (value !== "3") {
        this.document.presented_date = null
      }
    },
    useDocumentMask(value) {
      // mask = ######/######/#####
      if (this.document.doc_code === '03031') {
        const symbols = value.split('')
        const errors = symbols.filter(i => i !== '/' && Number.isNaN(Number.parseInt(i)))
        if (errors.length || value.length > 19) {
          const {doc_number} = this.document
          this.document.doc_number = '' // Чтобы значение в v-text-field обновилось
          this.$nextTick(() => {
            this.document.doc_number = doc_number
          })
        } else {
          if (value.length > this.document.doc_number?.length && (value.length === 7 || value.length === 14)) {
            const symbols = value.split('').reverse()
            const [end, ...start] = symbols
            this.document.doc_number = [...start.reverse(), '/', end].join('')
          } else {
            this.document.doc_number = value
          }
        }
      } else {
        this.document.doc_number = value
      }
    },
    addNewDoc() {
      this.$emit('addNewDoc')
    },
    customFieldChecker(items, field, param) {
      if (field === null) {
        return false
      } else if (field === '') {
        return true
      } else {
        var positiveArr = items.filter(function (i) {
          return i[param] === field;
        })
        if (positiveArr.length) {
          return false
        } else {
          return true
        }
      }
    },
    updateSelected() {
      this.$emit('select', this.item.id)
    },
    startDrag(evt, document) {
      if (this.hasChanges && this.fields_been_visited) {
        return this.$snackbar({
          text: "Сохраните изменения перед сортировкой.",
          color: "orange",
          top: false,
          right: false
        });
      }
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("document", document.id);
    },
    mouseUp() {
      this.$emit("update:draggable", false);
    },
    mouseDown(evt) {
      if (
        [...evt.target.classList].includes("allow-drag") &&
          !this.forSingleItem
      ) {
        this.$emit("update:draggable", true);
      }
    },
    deleteNulls,
    deleteDocument() {
      const {id} = this.item;
      const index = this.index;
      this.$emit("delete", {id, index});
    },
    uploadData() {
      const index = this.index;
      const payload = convertEmptyStringsToNull(this.document);
      return this.$store.dispatch("dts/updateDocument", {
        index,
        payload,
      });
    },
    showDocuments() {
      showCatalog.trigger({
        type: "doctypes",
        id: this.document.id,
        search: this.document.doc_code,
      });
    },
    checkAndUpdateDocType({id, code}) {
      if (this.document.id === id) {
        this.document.doc_code = code;
        this.fieldsBeenVisitedByUser();
      }
    },

  },
};
</script>
<style scoped>
.document__row {
  display: inline-flex;
  align-items: center;
}

.document__select {
  display: flex;
  justify-content: center;
  align-items: center;
}

.document__sort {
  width: 40px !important;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}

.document__code {
  margin-right: 10px;
  width: 140px !important;
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
}

.document__sign {
  width: 160px !important;
  flex-grow: 0;
  flex-shrink: 0;
}

.document__number {
  width: 280px !important;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}

.document__date {
  width: 160px !important;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}

.document__documents-group{
  width: 400px !important;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}

.document__type {
  max-width: 80px;
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}

.document-row:last-child {
  padding: 0 10px 4px 0 !important;
}

.allow-drag {
  cursor: pointer !important;
}
</style>
