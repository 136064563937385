<template>
  <section
    class="pb-16 mx-auto sd-main"
    style="width: 1440px"
  >
    <dts-toolbox />
    <main-info />
    <v-container fluid>
      <v-row>
        <sender />
        <recipient />
        <declarant />
        <info />
      </v-row>
    </v-container>
    <dts-place-date />
    <documents />
    <questions v-if="selected.declaration_type == 'DTS1'" />
    <methods v-if="selected.declaration_type == 'DTS2'" />
    <reasons-list v-if="selected.declaration_type == 'DTS2'" />
    <goods />
    <catalogs />
  </section>
</template>

<script>
import DtsToolbox from '@/components/dts/dts-toolbox.vue'
import Sender from "@/components/dts/contragents/sender.vue";
import Recipient from "@/components/dts/contragents/recipient.vue";
import DtsPlaceDate from '@/components/dts/dts-place-date.vue'
import Documents from '@/components/dts/documents/documents.vue'
import Questions from '@/components/dts/questions-group/questions.vue'
import {mapGetters} from "vuex";
import {setDeclarationTitle} from "@/helpers/text";
import Catalogs from "@/components/catalogs/modals/resolver.vue"
import MainInfo from "@/components/dts/dts-main-info.vue"
import Declarant from "@/components/dts/contragents/declarant.vue";
import Info from "@/components/dts/info.vue";
import Goods from "@/components/dts/dts-goods.vue";
import Methods from '../../components/dts/methods.vue';
import ReasonsList from '../../components/dts/reasons/reasons-list.vue';


export default {
  components: {
    Goods,
    Info,
    Sender,
    Recipient,
    MainInfo,
    DtsToolbox,
    DtsPlaceDate,
    Documents,
    Questions,
    Catalogs,
    Declarant,
    Methods,
    ReasonsList
  },
  provide: {
    rootNamespace: 'dts'
  },
  data: () => ({
    menuItems: [
      {
        name: "Основная информация",
        id: "dt-main-info"
      },
      {
        name: "Декларант",
        id: "dt-declarant"
      },
      {
        name: "Получатель",
        id: "dt-recipient"
      },
      {
        name: "Отправитель",
        id: "dt-sender"
      },

      {
        name: "Место и дата",
        id: "dt-place-date"
      },
      {
        name: "Документы",
        id: "dt-documents"
      },
      {
        name: "Товары",
        id: "dt-goods-block",
      },
    ],
    tabs: [
      {
        name: 'documents',
        component: () => import('@/components/dt/documents/documents.vue')
      },
      {
        name: 'goods-character',
        component: () => import('@/components/dt/goods-character.vue')
      },
      {
        name: 'controls',
        component: () => import('@/components/dt/controls/common-errors.vue')
      },
    ],

  }),
  computed: {
    ...mapGetters({
      selected: 'dts/getSelected',
      pinned: "ui/getPinned",
    }),
  },
  watch: {
    selected: {
      handler(dtDeclaration) {
        const {remark, out_number: outNumber} = dtDeclaration?.about_declaration ?? {}
        setDeclarationTitle({
          outNumber,
          remark,
          prefix: "ДТС"
        })
      },
      deep: true
    }
  },
  created() {
    this.$store.dispatch("catalogs/fetchAll")
    this.$store.dispatch('dts/fetchNsiAeos')
    this.$store.dispatch('dts/fetchNsiTransportMarks')
    const id = this.$route.params.id
    this.$store.dispatch("dts/getDocumentById", id).catch(err => {
      if (err.response.status === 403) {
        this.$router.push('/403')
      }
    });
  },
}
</script>
