<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-col
      v-for="(group, index) in questionGroups"
      :key="`group-${index}`"
      cols="12"
      class="pb-8"
    >
      <v-row
        v-for="(q, idx) in group"
        :key="`question-${q.id}`"
        class="statistics-box"
        :class="{
          'rounded-b-0':idx === 0,
          'rounded-0': idx > 0 && idx < group.length - 1,
          'rounded-t-0': idx === group.length - 1,
        }"
      >
        <v-col cols="10">
          <p class="mb-0">
            <template v-if="q?.html">
              <p v-html="q.question" />
            </template>
            <template v-else>
              {{
                q.question
              }}
            </template>
          </p>
        </v-col>
        <v-col cols="2">
          <v-radio-group
            v-model="conditions_json[q.id]"
            class="start"
            hide-details
            row
            @change="readyToUpdate"
          >
            <v-radio
              color="#5cb7b1"
              label="Да"
              :value="true"
            />
            <v-radio
              color="#5cb7b1"
              label="Нет"
              :value="false"
            />
          </v-radio-group>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import {questionGroups} from "@/components/dts/questions-group/questions";
import blockAutoUpdateMixin from "@/mixins/block-auto-update.mixin";
import {convertEmptyStringsToNull} from "@/helpers/objects";
function defaultQuestionGroups(){
  return questionGroups
}
export default {
  mixins:[blockAutoUpdateMixin],
  data(){
    return {
      block_id:"dts-questions",
      questionGroups: defaultQuestionGroups(),
      conditions:{
        declaration_id:null
      },
      conditions_json:{
        condition_7a: null,
        condition_7b: null,
        condition_7v: null,
        condition_8a: null,
        condition_8b: null,
        condition_9a: null,
        condition_9b: null,
        condition_9v: null
      }
    }
  },
  computed:{
    ...mapGetters({
      selected:'dts/getSelected'
    })
  },
  watch:{
    selected:{
      handler(){
        this.setFields()
      },
      deep:true
    }
  },
  methods:{
    readyToUpdate(){
      this.fields_been_visited = true
      this.hasChanges = true
    },
    setFields(){
      this.conditions.declaration_id = this.selected.conditions.declaration_id
      const conditions_json = this.selected.conditions.conditions_json
      Object.keys(this.conditions_json).forEach(key => {
        if(key in conditions_json){
          this.conditions_json[key] = conditions_json[key]
        }
      })
    },
    uploadData(){
      const conditions_json = convertEmptyStringsToNull({
        ...this.conditions,
        conditions_json: this.conditions_json
      });
      return this.$store.dispatch("dts/uploadBlockData", {
        name: "conditions_json",
        value: conditions_json,
      });
    }
  }

}
</script>
