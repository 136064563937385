export const questionGroups =[
  [
    {
      id:"condition_7a",
      question:"7 (а) Имеется ли ВЗАИМОСВЯЗЬ между продавцом и покупателем в значении, указанном, в ст.37 Таможенного кодекса Евразийского экономического союза?*",
      value:null
    },
    {
      id:"condition_7b",
      question:"(б) Оказала ли взаимосвязь между продавцом и покупателем влияние на цену фактич. уплаченную или подлежащую уплате за ввозимые товары?",
      value:null
    },
    {
      id:"condition_7v",
      question:"(в) Стоимость сделки с ввозимыми товарами близка к одной из возможных проверочных величин, указанных в п.5 ст.39 Таможенного кодекса ЕАЭС?",
      value:null
    }
  ],
  [
    {
      id:"condition_8a",
      question:" 8 (a) Имеются ли ОГРАНИЧЕНИЯ в отношении прав покупателя на пользование и распоряжениеввозимыми товарами, за исключением ограничений, которые: <br>\n" +
        "            - ограничивают географический регион, в котором ввозимые товары могут быть перепроданы; <br>\n" +
        "            - существенно не влияют на стоимость ввозимых товаров; <br>\n" +
        "            - установлены актами органов ЕАЭС или законодательством государств-членов ЕАЭС.<br>",
      value:null,
      html:true,
    },
    {
      id:"condition_8b",
      question:"(б) Зависит ли продажа ввозимых товаров или их цена от соблюдения УСЛОВИЙ или ОБЯЗАТЕЛЬСТВ,оказывающих влияние на цену ввозимых товаров? <br>\n" + "Если влияние таких условий или обязательств на цену ввозимых товаров может быть количественно определено, сумма стоимостной оценки таких условий или обязательств указывается в разделе \"б\" гр.11.",
      value:null,
      html:true,
    }
  ],
  [
    {
      id:"condition_9a",
      question:"9(а) Имеются ли ДОГОВОРНЫЕ ОТНОШЕНИЯ (лицензионный договор (соглашение), сублицензионный договор (соглашение), договор коммерческой концессии (франчайзинга), договор коммерческой субконцессии (субфранчайзинга) либо иной договор (соглашение)), предусматривающие предоставление правообладателем прав на использование объектов ИНТЕЛЛЕКТУАЛЬНОЙ СОБСТВЕННОСТИ в отношении ввозимых товаров?",
      value:null
    },
    {
      id:"condition_9b",
      question:"(б) Предусмотрены ли ЛИЦЕНЗИОННЫЕ и иные подобные ПЛАТЕЖИ за использование объектов ИНТЕЛЛЕКТУАЛЬНОЙ СОБСТВЕННОСТИ, которые относятся к ввозимым товарам и которые прямо или косвенно произвел или должен произвести покупатель в качестве условия продажи ввозимых товаров для вывоза на таможенную территорию Евразийского экономического союза?",
      value:null
    },
    {
      id:"condition_9v",
      question:"(в) Зависит ли продажа от соблюдения условия, в соответствии с которым ЧАСТЬ ДОХОДА (ВЫРУЧКИ), полученного в результате ПОСЛЕДУЮЩЕЙ ПРОДАЖИ, РАСПОРЯЖЕНИЯ иным способом или ИСПОЛЬЗОВАНИЯ ввозимых товаров, прямо или косвенно причитается продавцу?<br>\n" + " В случае ответа \"ДА\" на вопросы в разделах \"б\" и(или) \"в\" в гр.15 и 16  указываются соответствующие величины. ",
      html:true,
      value:null
    },

  ]
]