<template>
  <div>
    <v-container
      :id="block_id"
      v-click-outside="triggerOnFocus"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <div
            class="statistics-box pa-4"
            :class="{
              'blocked-box': disabledView,
            }"
          >
            <v-row>
              <v-col
                cols="12"
                class="pb-0"
              >
                <div class="font-weight-bold d-flex align-center mt-2">
                  Причины, по которым предшествующие методы определения
                  таможенной стоимости неприменимы:
                </div>
              </v-col>
              <v-col
                v-if="!hasReasons"
                cols="12"
                class="text-center"
              >
                <v-btn
                  class="document-add elevation-0 px-15"
                  color="transparent"
                  @click="add"
                >
                  Добавить
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <reasons-item
                  v-for="(
                    item, index
                  ) in reasons.reasons_inapplicability_method"
                  :key="index"
                  :item="item"
                  :items="reasons.reasons_inapplicability_method"
                  :index="index"
                  :visited="fields_been_visited"
                  @update="update"
                  @remove="remove"
                  @add="add"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ReasonsItem from "./reasons-item.vue";
import blockAutoUpdateMixin from "@/mixins/block-auto-update.mixin";
import { convertEmptyStringsToNull } from "@/helpers/objects";

export default {
  components: {
    ReasonsItem,
  },
  mixins: [blockAutoUpdateMixin],
  data: () => ({
    block_id: "dts-reasons",
    reasons: {
      declaration_id: null,
      reasons_inapplicability_method: [],
    },
  }),
  computed: {
    ...mapGetters({
      selected: "dts/getSelected",
      user: "auth/getUser",
      disabledView: "dt/getVisibility",
    }),
    hasReasons() {
      return !!this.reasons.reasons_inapplicability_method.length;
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
        this.$nextTick(() => this.updateEventListeners());
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    readyToUpdate() {
      this.hasChanges = true;
      this.fields_been_visited = true;
    },
    add() {
      this.reasons.reasons_inapplicability_method.push("");
      this.readyToUpdate();
    },
    update({ index, value }) {
      this.reasons.reasons_inapplicability_method.splice(index, 1, value);
      this.readyToUpdate();
    },
    remove(index) {
      this.reasons.reasons_inapplicability_method.splice(index, 1);
      this.readyToUpdate();
    },
    setFields() {
      this.reasons.declaration_id = this.selected?.id;
      this.reasons.reasons_inapplicability_method =
        this.selected?.reasons_inapplicability_method || [];
    },
    uploadData() {
      const reasons = convertEmptyStringsToNull(this.reasons);
      return this.$store.dispatch("dts/uploadBlockData", {
        name: "reasons",
        value: reasons,
      });
    },
  },
};
</script>
