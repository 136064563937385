<template>
  <v-col
    fluid
    cols="6"
    order="1"
  >
    <v-row
      class="statistics-box ma-0"
    >
      <v-col
        cols="12"
      >
        <p class="font-weight-bold mb-6">
          ВАЖНАЯ ИНФОРМАЦИЯ!
        </p>
        <p class="text-body-1">
          Декларант несет ответственность в соответствии с законодательством государств - членов 
          Евразийского экономического союза за неисполнение обязанностей, предусмотренных пунктом 
          2 статьи 84 Таможенного кодекса Евразийского экономического союза, за заявление в таможенной 
          декларации недостоверных сведений, а также за представление таможенному представителю 
          недействительных документов, в том числе поддельных и (или) содержащих заведомо 
          недостоверные (ложные) сведения.
        </p>
        <strong class="text-body-1">*Лица являются взаимосвязанными исключительно в том случае, если они:</strong>
        <p class="text-body-1">
          (а) являются сотрудниками или директорами (руководителями) организаций друг друга; <br>
          (б) являются юридически признанными деловыми партнерами, т.е. связаны договорными
          отношениями, действуют в целях извлечения прибыли и совместно несут расходы и убытки,
          связанные с осуществлением совместной деятельности; <br>
          (в) являются работодателем и работником; <br>
          (г) какое-либо лицо прямо или косвенно владеет, контролирует или является держателем 5
          или более процентов выпущенных в обращение голосующих акций обоих из них; <br>
          (д) одно из них прямо или косвенно контролирует другое; <br>
          (е) оба они прямо или косвенно контролируются третьим лицом; <br>
          (ж) вместе они прямо или косвенно контролируют третье лицо; <br>
          (з) являются родственниками или членами одной семьи.
        </p>
        <p class="text-body-1 mb-14">
          Факт взаимосвязи сам по себе не должен являться основанием для признания стоимости 
          сделки неприемлемой для определения таможенной стоимости товаров <strong>(пункт 4 
            статьи 39 Таможенного кодекса Евразийского экономического союза).</strong>
        </p>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>

export default {
  
}
</script>
