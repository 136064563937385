<template>
  <div>
    <v-container
      :id="block_id"
      v-click-outside="triggerOnFocus"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <div class="statistics-box d-flex col-12">
            <v-col cols="12">
              <v-row>
                <span class="font-weight-bold">
                  Таможенная стоимость декларируемых товаров определяется по
                  методу:
                </span>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="6">
                      <label>
                        Номер основного метода
                        <div class="d-flex">
                          <v-text-field
                            v-model="selected.valuation_method_code"
                            outlined
                            hide-details
                            dense
                            background-color="grey lighten-2"
                            disabled
                          />
                        </div>
                      </label>
                    </v-col>
                    <v-col cols="6">
                      <label>
                        Номер базового метода
                        <div class="d-flex">
                          <v-text-field
                            v-model="selected.base_valuation_method_code"
                            outlined
                            hide-details
                            dense
                            background-color="grey lighten-2"
                            disabled
                          />
                        </div>
                      </label>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import blockAutoUpdateMixin from "@/mixins/block-auto-update.mixin";

export default {
  mixins: [blockAutoUpdateMixin],
  data() {
    return {
      block_id: "dts-methods",
    };
  },
  computed: {
    ...mapGetters({
      selected: "dts/getSelected",
    }),
  },
};
</script>
