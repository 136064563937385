<template>
  <div
    class="d-flex align-center mt-2"
    style="gap: 20px"
  >
    <div style="width: 95%">
      <v-combobox
        v-model="state"
        :items="reasonsGroup"
        outlined
        dense
        auto-select-first
        hide-details
        background-color="white"
      />
    </div>
    <div
      class="d-flex"
      style="width: 72px"
    >
      <v-btn
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="$emit('remove', index)"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="index === items.length - 1"
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        @click="$emit('add')"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import { mapGetters } from "vuex";

export default {
  mixins: [filterBySearchFieldMixin],
  props: {
    visited: {
      required: true,
      type: Boolean,
    },
    items: {
      required: true,
      type: Array,
    },
    item: {
      required: true,
      type: String,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      state: "",
    };
  },
  computed: {
    ...mapGetters({
      selected: "dts/getSelected",
    }),
  },
  watch: {
    item: {
      handler(value) {
        this.state = value;
      },
      immediate: true,
      deep: true,
    },
    state: {
      handler(value) {
        if (this.visited) this.$emit("update", { index: this.index, value });
      },
      deep: true,
    },
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setFields() {
      this.reasonsGroup =
        [
          "МЕТОД 1 НЕПРИМЕНИМ, В СВЯЗИ С ТЕМ, ЧТО ОТСУТСТВУЕТ ДОСТОВЕРНАЯ, КОЛИЧЕСТВЕННО ОПРЕДЕЛЯЕМАЯ И ДОКУМЕНТАЛЬНО ПОДТВЕРЖДЕННАЯ ИНФОРМАЦИЯ О ДОПОЛНИТЕЛЬНЫХ НАЧИСЛЕНИЯХ К ЦЕНЕ (ПУНКТ 3 СТАТЬИ 40 ТК ЕАЭС)",
          "МЕТОД 2 НЕПРИЕМЛЕМ, Т.К. ОТСУТСТВУЮТ ДАННЫЕ О ПРОДАЖЕ В РБ ИДЕНТИЧНЫХ ТОВАРОВ",
          "МЕТОД 3 НЕПРИМЕНИМ, В СВЯЗИ С ОТСУТСТВИЕМ О ПРОДАЖЕ В РБ ОДНОРОДНЫХ ТОВАРОВ",
          "МЕТОД 4 НЕПРИЕМЛЕМ, Т.К. ОТСУТСТВУЮТ ДАННЫЕ НЕОБХОДИМЫЕ ДЛЯ ПРИМЕНЕНИЯ 4 МОС",
          "МЕТОД 5 НЕПРИЕМЛЕМ, Т.К. ОТСУТСТВУЮТ ДАННЫЕ НЕОБХОДИМЫЕ ДЛЯ ПРИМЕНЕНИЯ 5 МОС",
        ] || [];
    },
  },
};
</script>
